<template>
  <div class="fill-parent">
    <input type="file" ref="inputFile" @input="inputFile"/>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
// import imglyRemoveBackground from "@imgly/background-removal"

export default {
  name: 'Test',
  data() {
    return {
      file: null,
    };
  },
  components: {
    
  },
  computed: {
    ...mapState(['clothCategories']),
    
	},
  watch: {
    
  },
  async mounted() {
    // const imgSrc = 'https://walkingcloset.s3.ap-northeast-3.amazonaws.com/1697130297729.png';
    // imglyRemoveBackground(imgSrc).then((blob) => {
    //   const url = URL.createObjectURL(blob);
    //   console.log(url);
    // })
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal', 'updateCloth']),
    async inputFile() {
      const file = this.$refs.inputFile.files[0];
      const blob = await this.readFileToBlobPromise(file);
      console.log(blob);
    },
    readFileToBlobPromise(file) {
      return new Promise((result, reject) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
          result(blob);
        };
        reader.onerror = (e) => {
          reject(e);
        }
        reader.readAsArrayBuffer(file);
      });
    },
    // removeBgPromise(blob) {
    //   return new Promise((result) => {
    //     imglyRemoveBackground(blob).then((resBlob) => {
    //       // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
    //       result(resBlob);
    //     })
    //   });
    // },
  }
}
</script>

<style lang="scss" scoped>
  .fill-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

</style>
